<script>
import MasterDetail from "@/components/master-detail.vue";
import { mapGetters } from "vuex";

export default {
  components: { MasterDetail },
  computed: {
    ...mapGetters(["clientId"]),
    cols() {
      return [
        {
          key: "senioridade",
          name: "Senioridade",
          type: this.$fieldTypes.TEXT,
          rules: [{ rule: 'required' }]
        },
        {
          key: "especialidade",
          name: "Especialidade",
          type: this.$fieldTypes.TEXT,
          rules: [{ rule: 'required' }]
        },
      ];
    },
    resourceUrl() {
      return `v1/ativacaoped/${this.clientId}/perfil`;
    }
  },
  methods: {
    doImport() {
      window.alert("TO DO Implementar");
    }
  }
};
</script>

<template>
  <MasterDetail
    :canDelete="false"
    :cols="cols"
    :hasFilter="false"
    :hasExportCSV="false"
    newButtonText="Adicionar perfil"
    :resourceUrl="resourceUrl"
    serverPagination
  >
    <div class="d-flex">
      <v-spacer></v-spacer>
      <slot name="actionButtons"></slot>
      <!-- <div
        aria-label="importButton"
        class="table-v-action-button mr-3 pt-1"
        @click="doImport()"
      >
        <v-icon>mdi-upload-box</v-icon> Importações
      </div> -->
    </div>
  </MasterDetail>
</template>
